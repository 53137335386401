<template>
  <div class="grid px-10 mt-3">
    <div class="col-12 lg:col-12 border">
      <h1>DashBoard Admin</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardAdmin",
};
</script>
