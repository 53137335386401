<template>
    <div class="dash-main">
      <h1>DashBoard</h1>
    </div>
</template>

<script>

export default {
    name: 'DashboardPage',
  };
</script>
