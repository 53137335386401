<!-- About.vue -->
<template>
    <div>
      <h2>About Page</h2>
      <p>This is the about page content.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutpageMain',
  };
  </script>
  